export * from './lib/core.module';

export { application, date, dependencies } from './versions';

// Configurations 
export { PerfectScrollbarConfig } from './lib/configurations/perfect-scrollbar.config';

// Services
export { DataService } from './lib/services/data.service';
export { HotspotService } from './lib/services/hotspot.service';
export { PerfectScrollbarService } from './lib/services/perfect-scrollbar.service';
export { UploaderService } from './lib/services/uploader.service';

// Tokens
export { JQUERY, jqueryFactory } from './lib/tokens/jquery.token';
export { WINDOW, windowFactory } from './lib/tokens/window.token';
