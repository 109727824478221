<div class="container">

  <h1 i18n="@@info">Info</h1>

  <div class="content mat-elevation-z16">

    <div class="block">
      <img class="logo" src="../../../assets/images/logo-page2flip.svg">
    </div>

    <div class="block copyright">
      <span class="mat-body-1" i18n="@@infoCopyright1">Copyright © 2021 wissenswerft GmbH</span>
      <span class="mat-body-1" i18n="@@infoCopyright2">page2flip is a product of wissenswerft GmbH.</span>
    </div>

    <div class="block visit-us">
      <span class="mat-body-1" i18n="@@infoVisitUs">Visit us on the web at <a class="clickable" href="https://www.page2flip.de/" target="_blank">www.page2flip.de</a> or <a class="clickable" href="https://www.wissenswerft.net/" target="_blank">www.wissenswerft.net</a>.</span>
    </div>

    <div class="block contact">
      <span class="mat-body-1" i18n="@@infoTechnicalQuestions">If you have any technical questions please do not hesitate to contact us at <a class="clickable" href="mailto:support@page2flip.de" target="_blank">support@page2flip.de</a>.</span>
    </div>

    <div class="block imprint">
      <span class="mat-body-1"><a class="clickable" href="https://page2flip.de/impressum/" target="_blank" i18n="@@legalImprint">Imprint</a></span>
      <span class="mat-body-1"><a class="clickable" href="https://page2flip.de/nutzungsbedingungen/" target="_blank" i18n="@@legalTermsOfService">Terms of service</a></span>
      <span class="mat-body-1"><a class="clickable" href="https://page2flip.de/datenschutzerklaerung/" target="_blank" i18n="@@legalPrivacyPolicy">Privacy policy</a></span>
    </div>

    <div class="block versions" *ngIf="!environment.production">
      <span class="mat-body-2">
        <span class="name">page2flip</span>
        <span class="version">{{application.version}} (#{{application.revision}})</span>
      </span>
      <span class="mat-body-1">
        <span class="name">Nx</span>
        <span class="version">{{dependencies.nx}}</span>
      </span>
      <span class="mat-body-1">
        <span class="name">Schematics</span>
        <span class="version">{{dependencies.schematics}}</span>
      </span>
      <span class="mat-body-1">
        <span class="name">Angular CLI</span>
        <span class="version">{{dependencies.cli}}</span>
      </span>
      <span class="mat-body-1">
        <span class="name">Angular Core</span>
        <span class="version">{{dependencies.core}}</span>
      </span>
      <span class="mat-body-1">
        <span class="name">Angular Material</span>
        <span class="version">{{dependencies.mat}}</span>
      </span>
      <span class="mat-body-1">
        <span class="name">RxJS</span>
        <span class="version">{{dependencies.rxjs}}</span>
      </span>
      <span class="mat-body-1">
        <span class="name">SVG.js</span>
        <span class="version">{{dependencies.svgjs}}</span>
      </span>
      <span class="mat-body-1">
        <span class="name">TypeScript</span>
        <span class="version">{{dependencies.ts}}</span>
      </span>
      <span class="mat-body-1">
        <span class="name">Webpack</span>
        <span class="version">{{dependencies.webpack}}</span>
      </span>
      <span class="date">built on: {{date}}</span>
    </div>

  </div>

</div>
