import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DataService } from '@page2flip/core';
import { Publication } from '@page2flip/core/common';

import { PublicationDeleteDialogComponent } from '../publication-delete-dialog/publication-delete-dialog.component';

@Component({
  selector: 'p2f-home-publication-list',
  templateUrl: './home-publication-list.component.html',
  styleUrls: [ './home-publication-list.component.css' ],
  changeDetection: ChangeDetectionStrategy.Default
})
export class HomePublicationListComponent implements OnInit {
  @Input() publications: Publication[];
  readonly dateFormat: string =  'MMMM d, yyyy, HH:mm';

  constructor(
    private data: DataService,
    private dialog: MatDialog,
  ) { }

  ngOnInit() {
  }

  /**
   * Gets the cover URL of a publication.
   *
   * @param publicationId Publication ID
   */
  getCoverUrl(publicationId: string): string {
    return this.data.publicationUrl(publicationId, 'images/cover.png');
  }

  /**
   * Gets the wizard URL of a publication.
   *
   * @param publicationId Publication ID
   */
  getWizardUrl(publicationId: number): string {
    return this.data.wizardUrl(publicationId);
  }

  /**
   * Gets the URL of a publication.
   *
   * @param publicationId Publication ID
   */
  getPublicationUrl(publicationId: string): string {
    return this.data.publicationUrl(publicationId, 'index.html');
  }

  /**
   * Opens the dialog for deleting a publication.
   *
   * @param publication The publication.
   */
  openDeleteDialog(publication: Publication) {
    let dialogRef = this.dialog.open(PublicationDeleteDialogComponent, {
      data: publication,
      width: '800px',
    });
    dialogRef.componentInstance.updatePublications.subscribe((publications: Publication[]) => {
      this.publications = publications.sort((a, b) => new Date(b.dates.creationDate).getTime() - new Date(a.dates.creationDate).getTime()
      );
    })
  }

}
