import { AfterViewInit, Component, ElementRef, HostListener, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DataService, UploaderService, WINDOW } from '@page2flip/core';
import { Instance, Publication } from '@page2flip/core/common';
import { Subscription } from 'rxjs';

import { PDFRequirementsDialogComponent } from '../pdf-requirements-dialog/pdf-requirements-dialog.component';
import { PublicationDeleteDialogComponent } from '../publication-delete-dialog/publication-delete-dialog.component';

/**
 * Component that represents the home page.
 */
@Component({
  selector: 'p2f-home',
  templateUrl: './home.component.html',
  styleUrls: [ './home.component.css' ]
})
export class HomeComponent implements OnInit, AfterViewInit, OnDestroy {

  /** Reference to the content container. */
  @ViewChild('container') private containerRef: ElementRef;

  /** Reference to the file input. */
  @ViewChild('file') private fileInputRef: ElementRef;

  /** Reference to the publications container. */
  @ViewChild('list') private publicationsRef: ElementRef;

  /** Subscriptions of the component. */
  private readonly subscriptions: Subscription[] = [];

  /** Date format. */
  readonly dateFormat: string = 'MMMM d, yyyy, HH:mm';

  /** space.one instance. */
  readonly spaceOneInstance: Instance = JSON.parse(this.window.sessionStorage.getItem('spaceOneInstance'));

  /** Whether or not to hide the intro. */
  hideIntro: boolean;

  /** User publications. */
  publications: Publication[];

  /** Unfinished publications. */
  unfinishedPublications: {
    date: string;
    itemId: number;
    progress: number;
    status: string;
  }[] = [];

  /** Whether or not to display the `show all` icon and gradient. */
  showAll: boolean;

  /**
   * Constructor of the component.
   *
   * @param window    DI Token for the Window object.
   * @param data      Service to fetch data from the backend.
   * @param dialog    Service to open Material Design modal dialogs.
   * @param uploader  Service for uploading files to the backend.
   */
  constructor(
    @Inject(WINDOW) private window: any,
    private data: DataService,
    private dialog: MatDialog,
    private uploader: UploaderService
  ) {
  }

  /**
   * Handles the component subscriptions.
   */
  ngOnInit() {
    let unfinishedPublications: any[] = [];
    this.subscriptions[ this.subscriptions.length ] = this.data
      .loadPublications()
      .subscribe((publications: Publication[]) => {
        this.publications = publications
          .sort(
            (a, b) =>
              new Date(b.dates.creationDate).getTime() -
              new Date(a.dates.creationDate).getTime()
          )
          .slice(0, 20);

        unfinishedPublications.sort(
          (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
        );
        this.unfinishedPublications = unfinishedPublications;

        // reload publications every 10s
        if (this.unfinishedPublications.length) {
          setTimeout(() => {
            this.ngOnDestroy();
            this.ngOnInit();
          }, 1000);
        }

        setTimeout(() => this.onResize(), 500);
      });
  }

  ngAfterViewInit(): void {
    this.subscriptions[ this.subscriptions.length ] = this.uploader.reset().subscribe(() => (this.fileInputRef.nativeElement.value = ''));
  }

  /**
   * Destroys the component subscriptions.
   */
  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  /**
   * Resize event listener.
   */
  @HostListener('window:resize')
  onResize() {
    if (this.publicationsRef) {
      this.showAll = this.containerRef.nativeElement.clientWidth - this.publicationsRef.nativeElement.clientWidth < 370;
    }
  }

  /**
   * Sends PDF files to the uploader component.
   *
   * @param event File input event.
   */
  onFileInput(event: Event | any) {
    // file dialog closed without a file selected
    if (event.target.files && !event.target.files.length) {
      return;
    }

    const file: File = event.target.files[ 0 ];
    const parts: string[] = file.name.split('.');
    const type: string = parts[ parts.length - 1 ].toLowerCase();

    // wrong file type
    if (type !== 'pdf') {
      return;
    }

    this.uploader.sendFile(file);
  }

  /**
   * Opens the PDF requirements dialog.
   */
  openPDFRequirementsDialog() {
    this.dialog.open(PDFRequirementsDialogComponent, { width: '820px' });
  }

  /**
   * Opens the dialog for deleting a publication.
   *
   * @param publication The publication.
   */
  openDeleteDialog(publication: Publication) {
    this.dialog.open(PublicationDeleteDialogComponent, {
      data: publication,
      width: '800px'
    });
  }

  /**
   * Updates publications after reload.
   *
   * @param publications
   */
  update(publications) {
    this.publications = publications.sort(
      (a, b) =>
        new Date(b.dates.creationDate).getTime() -
        new Date(a.dates.creationDate).getTime()
    );
  }

  /**
   * Cleans the saved hotspots cache and sets the default current page on loading new publication.
   */
  resetCache() {
    this.data.getSelectedPageNumber(1);
    this.window.localStorage.removeItem('hotspots');
  }

}
