import { Component } from '@angular/core';

/**
 * Component that represents the browser error overlay.
 */
@Component({
  selector: 'p2f-browser-error',
  templateUrl: './browser-error.component.html',
  styleUrls: ['./browser-error.component.css']
})
export class BrowserErrorComponent {}
