<nav class="mat-elevation-z8" *ngIf="auth.currentUser">
  <div class="center">
    <a routerLink="/home" routerLinkActive="active">
      <mat-icon class="custom-icon"
                i18n-matTooltip="@@home"
                matTooltip="Home"
                matTooltipPosition="right"
                svgIcon="home"></mat-icon>
    </a>
    <a routerLink="/publications" routerLinkActive="active">
      <mat-icon i18n-matTooltip="@@publications"
                matTooltip="Publications"
                matTooltipPosition="right">list</mat-icon>
    </a>
    <a routerLink="/feedback" routerLinkActive="active">
      <mat-icon class="custom-icon"
                i18n-matTooltip="@@feedback"
                matTooltip="Feedback"
                matTooltipPosition="right"
                svgIcon="feedback"></mat-icon>
    </a>
  </div>

  <div>
    <a routerLink="/settings" routerLinkActive="active">
      <mat-icon class="custom-icon"
                i18n-matTooltip="@@settings"
                matTooltip="Settings"
                matTooltipPosition="right"
                svgIcon="settings"></mat-icon>
    </a>
    <a routerLink="/info" routerLinkActive="active">
      <mat-icon i18n-matTooltip="@@info"
                matTooltip="Info"
                matTooltipPosition="right">info_outline</mat-icon>
    </a>
    <a (click)="logout()">
      <mat-icon i18n-matTooltip="@@actionLogOut"
                matTooltip="Log out"
                matTooltipPosition="right">exit_to_app</mat-icon>
    </a>
  </div>
</nav>

<main [class.content]="content">
  <router-outlet></router-outlet>
</main>
