import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { User } from '@page2flip/core/common';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';

/**
 * Service to authenticate the user.
 */
@Injectable()
export class AuthenticationService {

  /** Current user. */
  currentUser: User;

  /** Redirect URL. */
  redirectUrl: string;

  constructor(
    private http: HttpClient
  ) { }

  /**
   * Logs the user in.
   *
   * @param username  Username.
   * @param password  Password.
   */
  login(username: string, password: string): Observable<void> {
    const token: string = btoa(username + ':' + password);
    const headers: HttpHeaders = new HttpHeaders().set('Authorization', 'Basic ' + token);

    return this.http.get<void>(environment.spaceOneApiUrl + '/login', { headers });
  }

  /**
   *  Logs the user out.
   */
  logout(): Observable<{}> {
    return this.http.get(environment.spaceOneApiUrl + '/logout');
  }

  /**
   * Gets the scopes.
   */
  scope(): Observable<any> {
    return this.http.get<any>(environment.spaceOneApiUrl + '/scopes');
  }

  /**
   * Gets the user profile.
   */
  profile(): Observable<User> {
    return this.http.get<User>(environment.spaceOneApiUrl + '/me');
  }

  /**
   * Updates the user profile.
   *
   * @param user  User profile.
   */
  updateProfile(user: User): Observable<User> {
    return this.http.put<User>(environment.spaceOneApiUrl + '/me', user);
  }

  /**
   * Updates the user password.
   *
   * @param currentPassword Current user password.
   * @param newPassword     New user password.
   */
  updatePassword(currentPassword: string, newPassword: string): Observable<User> {
    return this.http.put<User>(environment.spaceOneApiUrl + '/me/password', { currentPassword, newPassword });
  }

}
