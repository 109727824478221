<section *ngIf="dragging" class="dragging">
  <div class="icon"></div>
  <div class="message" i18n="@@uploaderFileDrop">Drop your PDF file here.</div>
</section>

<section *ngIf="error" class="error">
  <mat-icon>error_outline</mat-icon>
  <div class="message">{{ error }}</div>
  <button
    mat-button
    class="mat-elevation-z4"
    (click)="reset()"
    i18n="@@actionClose"
  >
    Close
  </button>
</section>

<section *ngIf="file" class="file">
  <div class="icon"></div>
  <div class="message file-name">{{ file.name }}</div>
  <div class="message file-size" i18n="@@uploaderFileSize">
    File size: {{ file.size / 1024 / 1024 | number: '1.2-2' }} MB
  </div>
  <div class="message file-time" i18n="@@uploaderFileDate">
    Modification date: {{ file.lastModified | date: dateFormat }}
  </div>
  <div class="message confirm" i18n="@@uploaderConfirm">
    Do you want to upload this PDF file?
  </div>
  <div>
    <button
      mat-button
      class="mat-elevation-z4"
      (click)="reset()"
      i18n="@@actionCancel"
    >
      Cancel
    </button>
    <button
      mat-button
      class="mat-elevation-z4"
      (click)="upload()"
      i18n="@@actionUpload"
    >
      Upload
    </button>
  </div>
</section>

<section *ngIf="uploading" class="uploading">
  <mat-icon>autorenew</mat-icon>
  <div class="message" i18n="@@uploaderUploading">
    Please wait while we finish uploading your file.
  </div>
  <div class="spinner-container">
    <mat-progress-spinner class="spinner" [color]="'primary'" [mode]="'indeterminate'" [value]="60">
    </mat-progress-spinner>
    <h1 >{{ uploadProgress }} %</h1>
  </div>
</section>
