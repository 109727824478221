<div class="browser-error">
  <p i18n="@@browserError1">Your browser is currently not supported by page2flip.</p>
  <p i18n="@@browserError2">Please use one of our supported browsers.</p>
</div>

<div>
  <a href="https://www.google.com/chrome/browser/desktop/">
    <div class="logo" browser="chrome"></div>
  </a>
  <a href="https://www.mozilla.org/firefox/new/">
    <div class="logo" browser="firefox"></div>
  </a>
  <a href="https://www.microsoft.com/software-download/windows10">
    <div class="logo" browser="edge"></div>
  </a>
  <a href="https://www.apple.com/osx/">
    <div class="logo" browser="safari"></div>
  </a>
  <a href="https://www.opera.com/download/">
    <div class="logo" browser="opera"></div>
  </a>
</div>
