/** Viewer languages. */
export const languages = {
  cs: {
    name: 'Czech',
    nativeName: 'Čeština'
  },
  de: {
    name: 'German',
    nativeName: 'Deutsch'
  },
  en: {
    name: 'English',
    nativeName: 'English'
  },
  es: {
    name: 'Spanish',
    nativeName: 'Español'
  },
  fr: {
    name: 'French',
    nativeName: 'Français'
  },
  it: {
    name: 'Italian',
    nativeName: 'Italiano'
  },
  sv: {
    name: 'Swedish',
    nativeName: 'Svenska'
  },
  zh: {
    name: 'Chinese',
    nativeName: '中文'
  }
};
