import { DOCUMENT } from '@angular/common';
import { Component, HostBinding, HostListener, Inject, Input, OnInit } from '@angular/core';

/**
 * Component that represents the browser dimensions overlay.
 */
@Component({
  selector: 'p2f-browser-dimensions',
  templateUrl: './browser-dimensions.component.html',
  styleUrls: ['./browser-dimensions.component.css']
})
export class BrowserDimensionsComponent implements OnInit {

  /** Minimum height passed to the component. */
  @Input() height = -Infinity;

  /** Minimum width passed to the component. */
  @Input() width = -Infinity;

  /** Whether or not the overlay is visible. */
  @HostBinding('class.visible') visibility = false;

  /**
   * Constructor of the component.
   *
   * @param document  DI Token for the Document object.
   */
  constructor(@Inject(DOCUMENT) private document: any) {}

  /**
   * Lifecycle hook that is called the component has been initialized.
   */
  ngOnInit() {
    this.setVisibility();
  }

  /**
   * Resize event listener.
   */
  @HostListener('window:resize')
  onResize() {
    this.setVisibility();
  }

  /**
   * Sets the component visibility.
   */
  private setVisibility() {
    this.visibility = this.height > this.document.body.clientHeight || this.width > this.document.body.clientWidth;
  }

}
