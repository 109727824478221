
/** German translations. */
export const translations = {

  __localeID: 'de',

  _dateFormat: 'dd.MM.yy',
  _shortDateFormat: 'dd.MM.yy, HH:mm',
  _mediumDateFormat: 'd. MMMM yyyy, HH:mm',
  _longDateFormat: 'EEEE, d. MMMM yyyy, HH:mm',

  _keyHome: 'Pos1',
  _keyEnd: 'Ende',
  _keyShift: '⇧ Umsch',
  _keyAlt: 'Alt',
  _keyControl: '✲ Strg',

  pageNotFound: 'Die von Ihnen angeforderte Seite wurde nicht gefunden.',

  accountApiAuthKey: 'API-Autorisierungsschlüssel',
  accountAuthorities: 'Berechtigungen',
  accountEmail: 'E-Mail Adresse',
  accountFirstName: 'Vorname',
  accountLanguage: 'Sprache',
  accountLastName: 'Nachname',
  accountPassword: 'Password',
  accountPasswordRetype: 'Password wiederholen',
  accountScopes: 'Bereiche',
  accountTitle: 'Anrede',
  accountTitleMr: 'Herr',
  accountTitleMs: 'Frau',
  accountUsername: 'Benutzername',

  actions: 'Aktionen',
  actionCancel: 'Abbrechen',
  actionClose: 'Schließen',
  actionCollapse: 'Einklappen',
  actionCompare: 'Vergleichen',
  actionCreate: 'Erstellen',
  actionDelete: 'Löschen',
  actionDownload: 'Herunterladen',
  actionDownloadAsZip: 'Als ZIP herunterladen',
  actionEdit: 'Bearbeiten',
  actionEditHotspots: 'Hotspots bearbeiten',
  actionExpand: 'Ausklappen',
  actionExportAsExcel: 'Als Excel exportieren',
  actionLoad: 'Laden',
  actionLogIn: 'Anmelden',
  actionLogOut: 'Abmelden',
  actionMove: 'Verschieben',
  actionOpenInNewWindow: 'In neuem Fenster öffnen',
  actionPageMarkerAdd: 'Seitenmarkierung hinzufügen',
  actionPageMarkerRemove: 'Seitenmarkierung entfernen',
  actionPrint: 'Drucken',
  actionQuantityDecrease: 'Anzahl verringern',
  actionQuantityIncrease: 'Anzahl vergrößern',
  actionRedo: 'Wiederholen',
  actionRelease: 'Veröffentlichen',
  actionRemove: 'Entfernen',
  actionReplace: 'Ersetzen',
  actionReset: 'Zurücksetzen',
  actionSave: 'Speichern',
  actionSend: 'Senden',
  actionShowAll: 'Alle anzeigen',
  actionUndo: 'Rückgängig',
  actionUnrelease: 'Unveröffentlichen',
  actionUpload: 'Hochladen',
  actionView: 'Anzeigen',

  backgroundSizeCenter: 'Zentrieren',
  backgroundSizeFill: 'Ausfüllen',
  backgroundSizeRepeat: 'Wiederholen',
  backgroundSizeStretch: 'Strecken',

  bookmarks: 'Lesezeichen',
  bookmarksAdded: 'Seite {{page}} zu Lesezeichen hinzugefügt.',
  bookmarksNone: 'Sie haben noch keine Lesezeichen. Öffnen Sie die Seitenübersicht und klicken Sie auf das <x id="START_TAG_MAT-ICON" ctype="x-mat-icon" equiv-text="&lt;mat-icon&gt;"/>bookmark<x id="CLOSE_TAG_MAT-ICON" ctype="x-mat-icon" equiv-text="&lt;/mat-icon&gt;"/> Symbol, um eine Seite zu Ihren Lesezeichen hinzuzufügen.',
  bookmarksRemoved: 'Seite {{page}} von Lesezeichen entfernt.',

  browserDimensions: 'page2flip ist nicht kompatibel mit dieser Fenstergröße. Bitte ändern Sie die Fenstergröße.',
  browserError1: 'Ihr Browser wird derzeit von page2flip nicht unterstützt.',
  browserError2: 'Bitte benutzen Sie einen unserer unterstützten Browser.',

  config: 'Konfiguration',
  configAnimateCorners: 'Ecken animieren',
  configAnimateHotspots: 'Hotspots animieren',
  configBackgroundImage: 'Hintergrundbild',
  configBackgroundSize: 'Hintergrundgröße',
  configCornerSize: 'Eckengröße (px)',
  configCoverPage: 'Cover-Seite (nur Apps)',
  configCurrency: 'Währung',
  configCustomCSS: 'Eigenes CSS',
  configCustomJS: 'Eigenes JavaScript',
  configDesign: 'Design',
  configDocumentMargin: 'Dokumentabstand (px)',
  configDoublePage: 'Doppelseiten',
  configFeatures: 'Features',
  configHideToolbar: 'Toolbar ausblenden',
  configIndexing: 'Suchmaschinenindexierung',
  configLayout: 'Layout',
  configLoader: 'Bilder vorladen',
  configMenuPosition: 'Menüposition',
  configMiscellaneous: 'Sonstiges',
  configPageFlipAnimation: 'Blättereffekt',
  configPageFlipSound: 'Blättergeräusch',
  configPageMarkerWidth: 'Seitenmarkierungsbreite (px)',
  configPrimaryBackgroundColor: 'Hintergrundfarbe 1',
  configPrimaryForegroundColor: 'Textfarbe 1',
  configSecondaryBackgroundColor: 'Hintergrundfarbe 2',
  configSecondaryForegroundColor: 'Textfarbe 2',
  configShop: 'Shop',
  configShopArticleRegExp: 'Artikelnummer-Regex',
  configShopEndpoint: 'Endpoint',
  configSidePanelPosition: 'Seitenpanelposition',
  configToolbarPosition: 'Toolbarposition',
  configTracking: 'Tracking',
  configTransparency: 'Transparenz',
  configVat: 'Mehrwertsteuer (%)',
  configVatIncluded: 'inklusive MwSt.',

  download: 'Herunterladen',
  downloadAllPages: 'Alle Seiten',
  downloadPages: 'Seiten',
  downloadPagesPlaceholder: 'z. B. 1-5, 8, 11-13',
  downloadSelectedPages: 'Ausgewählte Seiten',

  editorInfoSelect: 'Zeichnen Sie ein Rechteck, um die Hotspots innerhalb auszuwählen.',
  editorInfoDrawRect: 'Drücken Sie die linke Maustaste, um mit dem Zeichnen eines rechteckigen Hotspots zu beginnen und lassen Sie diese los, um den Hotspot fertigzustellen.',
  editorInfoDrawCircle: 'Drücken Sie die linke Maustaste, um mit dem Zeichnen eines kreisförmigen Hotspots zu beginnen und lassen Sie diese los, um den Hotspot fertigzustellen.',
  editorInfoDrawEllipse: 'Drücken Sie die linke Maustaste, um mit dem Zeichnen eines elliptischen Hotspots zu beginnen und lassen Sie diese los, um den Hotspot fertigzustellen.',
  editorInfoDrawPolygon1: 'Drücken Sie die linke Maustaste, um einen neuen polygonen Hotspot zu zeichnen oder um einen Punkt zum Polygon hinzuzufügen.',
  editorInfoDrawPolygon2: 'Drücken Sie die rechte Maustaste oder <x id="START_TAG_KBD" ctype="x-kbd" equiv-text="&lt;kbd&gt;"/>⏎ Eingabe<x id="CLOSE_TAG_KBD" ctype="x-kbd" equiv-text="&lt;/kbd&gt;"/>, um das Polygon fertigzustellen.',
  editorInfoDraw1: 'Drücken Sie während des Zeichnens eines Hotspots auf <x id="START_TAG_KBD" ctype="x-kbd" equiv-text="&lt;kbd&gt;"/>Esc<x id="CLOSE_TAG_KBD" ctype="x-kbd" equiv-text="&lt;/kbd&gt;"/>, um abzubrechen.',
  editorInfoDraw2: 'Während des Zeichnens eines Hotspots können Sie <x id="START_TAG_KBD" ctype="x-kbd" equiv-text="&lt;kbd&gt;"/>⇧ Umsch<x id="CLOSE_TAG_KBD" ctype="x-kbd" equiv-text="&lt;/kbd&gt;"/> gedrückt halten, um diesen am Raster auszurichten.',
  editorInfoDraw3: 'Klicken Sie auf einen Hotspot, um ihn auszuwählen.<x id="LINE_BREAK" ctype="lb" equiv-text="&lt;br/&gt;"/>Sie können mehrere Hotspots auswählen, während Sie <x id="START_TAG_KBD" ctype="x-kbd" equiv-text="&lt;kbd&gt;"/>✲ Strg<x id="CLOSE_TAG_KBD" ctype="x-kbd" equiv-text="&lt;/kbd&gt;"/> gedrückt halten und Sie können alle Hotspots auswählen, indem Sie <x id="START_TAG_KBD" ctype="x-kbd" equiv-text="&lt;kbd&gt;"/>✲ Strg<x id="CLOSE_TAG_KBD" ctype="x-kbd" equiv-text="&lt;/kbd&gt;"/> + <x id="START_TAG_KBD" ctype="x-kbd" equiv-text="&lt;kbd&gt;"/>A<x id="CLOSE_TAG_KBD" ctype="x-kbd" equiv-text="&lt;/kbd&gt;"/> drücken.',
  editorInfoDraw4: 'Sie können ausgewählte Hotspots mit <x id="START_TAG_KBD" ctype="x-kbd" equiv-text="&lt;kbd&gt;"/>✲ Strg<x id="CLOSE_TAG_KBD" ctype="x-kbd" equiv-text="&lt;/kbd&gt;"/> + <x id="START_TAG_KBD" ctype="x-kbd" equiv-text="&lt;kbd&gt;"/>C<x id="CLOSE_TAG_KBD" ctype="x-kbd" equiv-text="&lt;/kbd&gt;"/> kopieren und mit <x id="START_TAG_KBD" ctype="x-kbd" equiv-text="&lt;kbd&gt;"/>✲ Strg<x id="CLOSE_TAG_KBD" ctype="x-kbd" equiv-text="&lt;/kbd&gt;"/> + <x id="START_TAG_KBD" ctype="x-kbd" equiv-text="&lt;kbd&gt;"/>V<x id="CLOSE_TAG_KBD" ctype="x-kbd" equiv-text="&lt;/kbd&gt;"/> einfügen.',
  editorInfoDraw5: 'Drücken Sie <x id="START_TAG_KBD" ctype="x-kbd" equiv-text="&lt;kbd&gt;"/>Entf<x id="CLOSE_TAG_KBD" ctype="x-kbd" equiv-text="&lt;/kbd&gt;"/>, um ausgewählte Hotspots zu löschen.',
  editorInfoDelete: 'Klicken Sie auf einen Hotspot, um ihn zu löschen.',

  editorGrid: 'Raster',
  editorGridColor: 'Rasterfarbe',
  editorGridSize: 'Rastergröße (px)',
  editorGridVisibility: 'Raster anzeigen',
  editorGridSnapToGrid: 'Am Raster ausrichten',

  editorTools: 'Werkzeuge',
  editorToolDraw: 'Zeichnen',
  editorToolSelect: 'Auswählen',
  editorToolDelete: 'Löschen',

  feedback: 'Feedback',
  feedbackAdditionalInfo: 'Erzählen Sie uns etwas mehr (optional)',
  feedbackCategoryGeneral: 'Allgemein',
  feedbackCategoryUi: 'Benutzeroberfläche',
  feedbackConfirmation: 'Vielen Dank, dass Sie uns dabei helfen, page2flip noch weiter zu verbessern!',
  feedbackEmailAddress: 'Ihre E-Mail Adresse (optional)',
  feedbackError: 'Ihr Feedback konnte nicht gesendet werden. Bitte versuchen Sie es später noch einmal.',
  feedbackSelectCategory: 'Wählen Sie eine Kategorie aus',

  fullscreen: 'Vollbild',

  help: 'Hilfe',
  helpGestureMenu: 'Nach unten wischen, um das Menü zu öffnen und nach oben wischen, um es zu schließen.',
  helpGestureNav: 'Nach rechts oder links wischen, um zwischen den Seiten zu navigieren.',
  helpGestureZoom: 'Den Bildschirm doppelt antippen, um die Ansicht zu vergrößern.',
  helpKeyboardShortcuts: 'Tastenkürzel',
  helpNavigation: 'Navigation',
  helpPanelsAndDialogs: 'Panels & Dialoge',
  helpZoom: 'Zoom',

  home: 'Start',
  homeIntro: 'Ziehen Sie eine PDF-Datei in das Fenster oder klicken Sie auf den Button, um eine neue Veröffentlichung zu erstellen.',
  homeLetsRumble: 'Los geht\'s!',
  homeRequirements: 'Anforderungen an Ihr PDF',
  homeWelcome: 'Willkommen beim brandneuen page2flip!',

  hotspots: 'Hotspots',
  hotspotCount: '<x id="INTERPOLATION" equiv-text="{{editor.hotspots.length}}"/> Hotspots',
  hotspotCountSelected: '<x id="INTERPOLATION" equiv-text="{{editor.selectedHotspots.length}}"/> Hotspots ausgewählt',
  hotspotCountCopied: '<x id="INTERPOLATION" equiv-text="{{editor.copiedHotspots.length}}"/> Hotspots in Zwischenablage',

  hotspotActionTypeArticle: 'Artikel',
  hotspotActionTypeAudio: 'Audio',
  hotspotActionTypeExternal: 'Externer Link',
  hotspotActionTypeFeature: 'Feature',
  hotspotActionTypeGallery: 'Galerie',
  hotspotActionTypeIframe: 'iFrame',
  hotspotActionTypeImage: 'Bild',
  hotspotActionTypeInfo: 'Info',
  hotspotActionTypeInternal: 'Interner Link',
  hotspotActionTypeMulti: 'diverse',
  hotspotActionTypeNone: 'Bitte auswählen',
  hotspotActionTypeVideo: 'Video',

  hotspotDisplayTypeBorder: 'Rahmen',
  hotspotDisplayTypeBox: 'Box',
  hotspotDisplayTypeHidden: 'Versteckt',
  hotspotDisplayTypeHover: 'Hover',
  hotspotDisplayTypeIcon: 'Icon',
  hotspotDisplayTypeInline: 'Inline',
  hotspotDisplayTypeMulti: 'diverse',
  hotspotDisplayTypeNone: 'Bitte auswählen',

  hotspotProperties: 'Hotspot-Eigenschaften',
  hotspotPropertyType: 'Typ',
  hotspotPropertyActionInternal: 'Seitennummer',
  hotspotPropertyActionExternal: 'URL',
  hotspotPropertyActionVideo: 'URL (MP4, WebM, Ogg, YouTube, Vimeo, Dailymotion)',
  hotspotPropertyActionAudio: 'URL (MP3, WAV, Ogg)',
  hotspotPropertyActionArticle: 'Artikelnummer',
  hotspotPropertyActionDefault: 'Aktion',
  hotspotPropertyTitle: 'Titel',
  hotspotPropertyDescription: 'Beschreibung',
  hotspotPropertyDisplayType: 'Darstellungstyp',
  hotspotPropertyColor: 'Farbe',
  hotspotPropertyAutoplay: 'automatische Wiedergabe',
  hotspotPropertyPrice: 'Preis',
  hotspotPropertyPosition: 'Position',
  hotspotPropertyPositionX: 'X (px)',
  hotspotPropertyPositionY: 'Y (px)',
  hotspotPropertyDimensions: 'Abmessungen',
  hotspotPropertyDimensionWidth: 'Breite (px)',
  hotspotPropertyDimensionHeight: 'Höhe (px)',

  hotspotShapes: 'Formen',
  hotspotShapeRect: 'Rechteck',
  hotspotShapeCircle: 'Kreis',
  hotspotShapeEllipse: 'Ellipse',
  hotspotShapePolygon: 'Polygon',

  selectHotspotDisplayTypeError: 'Bitte wählen Sie einen Darstellungstyp',
  selectHotspotTypeError: 'Bitte wählen Sie einen Typ',

  info: 'Info',
  infoCopyright1: 'Copyright © 2021 wissenswerft GmbH',
  infoCopyright2: 'page2flip ist ein Produkt der wissenswerft GmbH.',
  infoTechnicalQuestions: 'Falls Sie technische Fragen haben, zögern Sie bitte nicht uns zu kontaktieren unter <x id="START_LINK" ctype="x-a" equiv-text="&lt;a&gt;"/>support@page2flip.de<x id="CLOSE_LINK" ctype="x-a" equiv-text="&lt;/a&gt;"/>.',
  infoVisitUs: 'Besuchen Sie uns im Web unter <x id="START_LINK" ctype="x-a" equiv-text="&lt;a&gt;"/>www.page2flip.de<x id="CLOSE_LINK" ctype="x-a" equiv-text="&lt;/a&gt;"/> oder <x id="START_LINK_1" ctype="x-a" equiv-text="&lt;a&gt;"/>www.wissenswerft.net<x id="CLOSE_LINK" ctype="x-a" equiv-text="&lt;/a&gt;"/>.',

  legal: 'Rechtliches',
  legalImprint: 'Impressum',
  legalPrivacyPolicy: 'Datenschutzerklärung',
  legalTermsOfService: 'Nutzungsbedingungen',

  loading: 'Lädt...',
  loadingPublication: 'Lade Veröffentlichung...',
  loadingPreview: 'Lade Vorschau...',

  loginInstance: 'space.one-Instanz',
  loginUsername: 'Benutzername',
  loginPassword: 'Passwort',
  loginStayLoggedIn: 'Angemeldet bleiben',

  menu: 'Menü',

  navigationFirstPage: 'Erste Seite',
  navigationLastPage: 'Letzte Seite',
  navigationNextPage: 'Nächste Seite',
  navigationPage: 'Seite',
  navigationPageBack: 'Seite zurück',
  navigationPreviousPage: 'Vorherige Seite',

  notes: 'Notizen',
  notesNone: 'Sie haben noch keine Notizen. Öffnen Sie die Seitenübersicht und klicken Sie auf das <x id="START_TAG_MAT-ICON" ctype="x-mat-icon" equiv-text="&lt;mat-icon&gt;"/>comment<x id="CLOSE_TAG_MAT-ICON" ctype="x-mat-icon" equiv-text="&lt;/mat-icon&gt;"/> Symbol, um Notizen zu einer Seite hinzuzufügen.',
  notesPlaceholder: 'Ihre Notizen',
  notesRemoved: 'Notizen für Seite {{page}} entfernt.',
  notesSaved: 'Notizen für Seite {{page}} gespeichert.',
  notesTitle: 'Notizen für Seite <x id="INTERPOLATION" equiv-text="{{pageNumber}}"/>',

  offlineAvailability: 'Offline-Verfügbarkeit (nur Apps)',

  pageComparison: 'Seitenvergleich',
  pageComparisonError: 'Bitte geben Sie eine gültige Seitennummer ein.',
  pageComparisonLeftPage: 'Linke Seite',
  pageComparisonRightPage: 'Rechte Seite',
  pageComparisonWarning: 'Der Seitenvergleich ist nicht verfügbar, wenn der Blättereffekt aktiviert ist.',

  pageFlipAnimationHint: 'Der Blättereffekt ist nur in der Desktopansicht verfügbar.',
  pageFlipAnimationWarning: 'Der Blättereffekt ist nicht verfügbar, wenn der Seitenvergleich aktiviert ist.',

  pageOverview: 'Seitenübersicht',
  leftPage: 'Linke Seite',
  rightPage: 'Rechte Seite',

  pageMarkers: 'Seitenmarkierungen',
  pageMarkerColor: 'Markerfarbe',
  pageMarkerLabel: 'Bezeichnung',
  pageMarkerLabelColor: 'Textfarbe',
  pageMarkerNew: 'Neue Seitenmarkierung',
  pageMarkerPage: 'Seite',
  pageMarkerWarning: 'Seitenmarkierungen sind nicht verfügbar, wenn der Blättereffekt aktiviert ist.',

  paginatorItemsPerPageLabel: 'Einträge pro Seite:',
  paginatorNextPageLabel: 'Nächste Seite',
  paginatorPreviousPageLabel: 'Vorherige Seite',
  paginatorFirstPageLabel: 'Erste Seite',
  paginatorLastPageLabel: 'Letzte Seite',
  paginatorRangeOfLabel: 'von',

  positionBottom: 'unten',
  positionLeft: 'links',
  positionRight: 'rechts',
  positionTop: 'oben',

  publication: 'Veröffentlichung',
  publications: 'Veröffentlichungen',
  publicationsLatest: 'Ihre letzten Veröffentlichungen',
  publicationAuthor: 'Autor',
  publicationCover: 'Cover',
  publicationDateCreated: 'Erstellt',
  publicationDateModified: 'Bearbeitet',
  publicationDatePublished: 'Veröffentlicht',
  publicationDelete: 'Veröffentlichung löschen',
  publicationDeleteMessage: 'Möchten Sie diese Veröffentlichung wirklich löschen? Beachten Sie, dass dies nicht rückgängig gemacht werden kann.',
  publicationDetails: 'Details',
  publicationDetailsEdit: 'Details bearbeiten',
  publicationDescription: 'Beschreibung',
  publicationHotspots: 'Hotspots',
  publicationKeywords: 'Stichwörter',
  publicationLanguage: 'Sprache',
  publicationPages: 'Seiten',
  publicationTitle: 'Titel',

  releaseApp: 'App',
  releaseDownload: 'Download',
  releaseOnline: 'Online',
  releasePreview: 'Vorschau',

  search: 'Suche',
  searchClear: 'Suchbegriff löschen',
  searchFoundMatches: '<x id="INTERPOLATION" equiv-text="{{searchResults.length}}"/> Seiten gefunden.',
  searchNoMatches: 'Keine Seiten gefunden. Geben Sie mindestens 3 Zeichen ein.',
  searchMatches: '<x id="INTERPOLATION" equiv-text="{{searchResult.matches}}"/> Treffer',
  searchPlaceholder: 'Wonach suchen Sie?',

  settings: 'Einstellungen',

  share: 'Teilen',
  shareCurrentPage: 'Aktuelle Seite teilen',
  shareSocialMedia: 'Social Media',
  shareUrl: 'URL',

  shopAdditionalInfo: 'Zusätzliche Informationen ✎',
  shopItemNumber: 'Art.-Nr.',
  shopVatExcluded: 'exkl. {{vat}} MwSt.',
  shopVatIncluded: 'inkl. {{vat}} MwSt.',

  shoppingCart: 'Warenkorb',
  shoppingCartAdd: 'Zum Warenkorb hinzufügen',
  shoppingCartAdded: '{{title}} zum Warenkorb hinzugefügt.',
  shoppingCartClear: 'Warenkorb leeren',
  shoppingCartCleared: 'Warenkorb geleert.',
  shoppingCartEmpty: 'Sie haben noch keine Artikel in Ihrem Warenkorb.',
  shoppingCartPayNow: 'Zur Kasse gehen',
  shoppingCartRemoved: '{{title}} aus dem Warenkorb entfernt.',
  shoppingCartSum: 'Summe',
  checkout: 'Kasse',
  checkoutEmpty: 'Sie haben keinen Artikel bereit zum Bezahlen.',

  tableOfContents: 'Inhaltsverzeichnis',
  tableOfContentsToggle: 'Alle Einträge aus-/einklappen',

  uploaderConfirm: 'Möchten Sie diese Datei hochladen?',
  uploaderErrorFileCount: 'Sie können nur eine PDF-Datei auf einmal hochladen.',
  uploaderErrorFileType: 'Sie können nur PDF-Dateien hochladen.',
  uploaderFileDate: 'Änderungsdatum: <x id="INTERPOLATION" equiv-text="{{file.lastModified | date:dateFormat}}"/>',
  uploaderFileDrop: 'Legen Sie Ihre PDF-Datei hier ab.',
  uploaderFileSize: 'Dateigröße: <x id="INTERPOLATION" equiv-text="{{file.size / 1024 / 1024 | number}}"/> MB',
  uploaderUploading: 'Bitte warten Sie, während wir Ihre Datei hochladen.',

  viewerPreview: 'Vorschau',
  viewerPreviewDesktop: 'Desktop',
  viewerPreviewPhone: 'Smartphone',
  viewerPreviewTablet: 'Tablet',
  viewerPreviewWarning: 'Die Vorschau kann von der tatsächlichen Darstellung abweichen.',

  watchList: 'Merkliste',
  watchListAdd: 'Zur Merkliste hinzufügen',
  watchListAdded: '{{title}} zur Merkliste hinzugefügt.',
  watchListClear: 'Merkliste löschen',
  watchListCleared: 'Merkliste gelöscht.',
  watchListEmpty: 'Sie haben noch keine Artikel auf Ihrer Merkliste.',
  watchListExists: '{{title}} ist bereits auf der Merkliste.',
  watchListRemoved: '{{title}} von der Merkliste entfernt.',

  wizardAnalysis: 'Auswertung',
  wizardAnalysisDescription: 'Statistik & Einblicke',
  wizardDocument: 'Dokument',
  wizardDocumentDescription: 'Übersicht & Metadaten',
  wizardHotspots: 'Hotspots',
  wizardHotspotsDescription: 'Erstellen & Bearbeiten',
  wizardPublication: 'Veröffentlichung',
  wizardPublicationDescription: 'Veröffentlichen & Teilen',
  wizardViewer: 'Viewer',
  wizardViewerDescription: 'Konfiguration & Vorschau',

  zoom: 'Zoom',
  zoomError: 'Doppelt auf den Bildschirm tippen, um zu zoomen.',
  zoomIn: 'Zoom vergrößern',
  zoomJumpBottom: 'Nach unten springen',
  zoomJumpLeft: 'Nach links springen',
  zoomJumpRight: 'Nach rechts springen',
  zoomJumpTop: 'Nach oben springen',
  zoomMoveDown: 'Nach unten bewegen',
  zoomMoveLeft: 'Nach links bewegen',
  zoomMoveRight: 'Nach rechts bewegen',
  zoomMoveTop: 'Nach oben bewegen',
  zoomOut: 'Zoom verringern',
  zoomReset: 'Zoom zurücksetzen',

  // vw-workshop-equipment specific translations

  vw_actionCreateCatalog: 'Katalog erstellen',
  vw_actionCreateNewCatalog: 'Neuen Katalog erstellen',
  vw_actionReset: 'Von vorn beginnen',
  vw_actionDownloadCatalog: 'Katalog herunterladen',
  vw_catalogLanguage: 'Katalogsprache',
  vw_catalogPreview: 'Katalogvorschau',
  vw_imprint: 'Impressum',
  vw_intro1: 'Herzlich willkommen im Creator des Verkaufskataloges Werkstattausrüstung der Volkswagen AG. Hier können Sie leicht und in wenigen Schritten Ihren marktindividuellen Katalog erstellen. Wir empfehlen übrigens die Nutzung der Browser Edge, Firefox oder Chrome hierfür.',
  vw_intro2: 'So einfach geht es:',
  vw_intro3: 'Nach ca. 30 Minuten ist der Erstellprozess fertig und Sie können in Ihrem Katalog stöbern, ihn als ZIP-Paket herunterladen oder den Erstellprozess neu starten.',
  vw_intro4: 'Haben Sie Fragen zum Katalog, Wünsche oder Verbesserungsideen, so freuen wir uns auf Ihre Rückmeldung unter <x id="START_LINK" ctype="x-a" equiv-text="&lt;a&gt;"/>Workshop.Equipment@volkswagen.de<x id="CLOSE_LINK" ctype="x-a" equiv-text="&lt;/a&gt;"/>.',
  vw_intro_list1: 'Laden Sie sich die Preislistenvorlage auf Ihren PC herunter. Es handelt sich dabei um eine CSV-Datei mit dem kompletten im Katalog enthaltenen Sortiment, den strategiekonformen HEP- und UPE-Angaben und den EU-Standards der Konzernmarken. Produkte mit Direktfakturierung sind mit dem Hinweis „<x id="START_ITALIC_TEXT" ctype="x-i" equiv-text="&lt;i&gt;"/>auf Anfrage<x id="CLOSE_ITALIC_TEXT" ctype="x-i" equiv-text="&lt;/i&gt;"/>“ in der Liste zu finden.<x id="START_LINK" ctype="x-a" equiv-text="&lt;a&gt;"/>Download Preislistenvorlage (Stand: Preise 01.09.2019, Standards 01.10.2018)<x id="CLOSE_LINK" ctype="x-a" equiv-text="&lt;/a&gt;"/>',
  vw_intro_list2: 'Öffnen Sie die CSV-Datei im Programm Excel.',
  vw_intro_list3: 'Tragen Sie Ihre marktindividuellen Preise in den Spalten HEP und UPE ein. Wollen Sie einen Preis nicht angeben oder ein Produkt in Ihrem Markt nicht anbieten, können Sie in das Preisfeld einfach „<x id="START_ITALIC_TEXT" ctype="x-i" equiv-text="&lt;i&gt;"/>auf Anfrage<x id="CLOSE_ITALIC_TEXT" ctype="x-i" equiv-text="&lt;/i&gt;"/>“ oder „<x id="START_ITALIC_TEXT" ctype="x-i" equiv-text="&lt;i&gt;"/>nicht verfügbar<x id="CLOSE_ITALIC_TEXT" ctype="x-i" equiv-text="&lt;/i&gt;"/>“ eintragen.',
  vw_intro_list4: 'Ergänzen Sie Ihre Hinweise zum Standard in den Spalten VW, Audi, SEAT, SKODA, VWN und Porsche. Geben Sie dort bitte eine „<x id="START_ITALIC_TEXT" ctype="x-i" equiv-text="&lt;i&gt;"/>0<x id="CLOSE_ITALIC_TEXT" ctype="x-i" equiv-text="&lt;/i&gt;"/>“ ein, wenn das Produkt bei der entsprechenden Marke kein Standard ist, oder tragen Sie eine „<x id="START_ITALIC_TEXT" ctype="x-i" equiv-text="&lt;i&gt;"/>1<x id="CLOSE_ITALIC_TEXT" ctype="x-i" equiv-text="&lt;/i&gt;"/>“ ein, wenn es zum Standard gehört.',
  vw_intro_list5: 'Ändern Sie keine Einträge in den Spalten „<x id="START_ITALIC_TEXT" ctype="x-i" equiv-text="&lt;i&gt;"/>Langtext (Technik)<x id="CLOSE_ITALIC_TEXT" ctype="x-i" equiv-text="&lt;/i&gt;"/>“ sowie „<x id="START_ITALIC_TEXT" ctype="x-i" equiv-text="&lt;i&gt;"/>Teilenummer<x id="CLOSE_ITALIC_TEXT" ctype="x-i" equiv-text="&lt;/i&gt;"/>“, damit eine reibungslose Funktion gewährleistet ist. Löschen oder ergänzen Sie zudem keine Spalten oder Zeilen und ändern Sie nicht die Spaltenüberschriften.',
  vw_intro_list6: 'Speichern Sie Ihre Preisliste als CSV-Datei lokal auf Ihrem PC.',
  vw_intro_list7: 'Laden Sie Ihre Preisliste über den untenstehenden Button „<x id="START_ITALIC_TEXT" ctype="x-i" equiv-text="&lt;i&gt;"/>Datei auswählen<x id="CLOSE_ITALIC_TEXT" ctype="x-i" equiv-text="&lt;/i&gt;"/>“ oder durch Ziehen der CSV-Datei in das graue Feld wieder hoch.',
  vw_intro_list8: 'Tragen Sie die Titelseiteninformationen ein: Ihr Markt, Ihre Preisstellung (HEP oder UPE) und den Preisstand (Gültigkeitsdatum der Preisinformation).',
  vw_intro_list9: 'Wählen Sie die Katalogsprache aus.',
  vw_intro_list10: 'Starten Sie den Erstellprozess über den Button „<x id="START_ITALIC_TEXT" ctype="x-i" equiv-text="&lt;i&gt;"/>Katalog erstellen<x id="CLOSE_ITALIC_TEXT" ctype="x-i" equiv-text="&lt;/i&gt;"/>“.',
  vw_legalNotice: 'Rechtliches',
  vw_loginError: 'Falsches Passwort.',
  vw_loginMessage: 'Bitte melden Sie sich an, um fortzufahren.',
  vw_market: 'Markt',
  vw_priceDate: 'Preisstand',
  vw_priceList: 'Preisliste',
  vw_priceType: 'Preisstellung',
  vw_priceTypeDNP: 'HEP',
  vw_priceTypeRRP: 'UPE',
  vw_stateConverting: 'Ihr Katalog wird erstellt. Dies kann einige Zeit in Anspruch nehmen.',
  vw_stateFailed: 'Ein Fehler ist aufgetreten. Bitte versuchen Sie es erneut.',
  vw_stateUploading: 'Ihre Daten werden hochgeladen. Bitte warten Sie einen Moment, während wir den Erstellungsprozess starten.',
  vw_stateWaiting: 'Ihr Katalog wurde zur Warteschlange hinzugefügt.',
  vw_title: 'Workshop Equipment Catalogue Creator',
  vw_uploaderMsgClickOrDragFile: 'oder CSV-Datei hierher ziehen.',
  vw_uploaderMsgDropFile: 'Datei hier ablegen.',
  vw_uploaderMsgFileCountError: 'Sie können nur eine Datei hochladen.',
  vw_uploaderMsgFileTypeError: 'Sie können nur CSV-Dateien hochladen.'

};
