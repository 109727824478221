import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CoreCommonModule } from '@page2flip/core/common';
import { ColorPickerModule } from 'ngx-color-picker';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { VirtualScrollerModule } from 'ngx-virtual-scroller';

import { MaterialModule } from '../material/material.module';

@NgModule({
  exports: [
    ColorPickerModule,
    CommonModule,
    CoreCommonModule,
    FormsModule,
    MaterialModule,
    PerfectScrollbarModule,
    VirtualScrollerModule,
  ]
})
export class SharedModule { }
