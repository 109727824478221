<h1 i18n="@@publications">Publications</h1>

<mat-form-field>
  <input
    matInput
    (keyup)="applyFilter($event.target.value)"
    placeholder="Filter"
  />
</mat-form-field>

<div class="table mat-elevation-z8" [perfectScrollbar]>
  <mat-table [dataSource]="dataSource" matSort>
    <ng-container matColumnDef="cover">
      <mat-header-cell *matHeaderCellDef i18n="@@publicationCover"
        >Cover</mat-header-cell
      >
      <mat-cell *matCellDef="let publication">
        <img class="cover" [src]="getCoverUrl(publication.id)" />
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="language">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        i18n="@@publicationLanguage"
        >Language</mat-header-cell
      >
      <mat-cell *matCellDef="let publication">{{
        getLanguageNativeName(publication.meta.language)
      }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="title">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        i18n="@@publicationTitle"
        >Title</mat-header-cell
      >
      <mat-cell *matCellDef="let publication">{{
        publication.meta.title
      }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="description">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        i18n="@@publicationDescription"
        >Description</mat-header-cell
      >
      <mat-cell *matCellDef="let publication">{{
        publication.meta.description
      }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="keywords">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        i18n="@@publicationKeywords"
        >Keywords</mat-header-cell
      >
      <mat-cell *matCellDef="let publication">{{
        publication.meta.keywords
      }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="author">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        i18n="@@publicationAuthor"
        >Author</mat-header-cell
      >
      <mat-cell *matCellDef="let publication">{{
        publication.meta.author
      }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="created">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        i18n="@@publicationDateCreated"
        >Created</mat-header-cell
      >
      <mat-cell *matCellDef="let publication">{{
        publication.dates.creationDate | date: dateFormat
      }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="modified">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        i18n="@@publicationDateModified"
        >Modified</mat-header-cell
      >
      <mat-cell *matCellDef="let publication">{{
        publication.dates.modificationDate | date: dateFormat
      }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="published">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        i18n="@@publicationDatePublished"
        >Published</mat-header-cell
      >
      <mat-cell *matCellDef="let publication">{{
        publication.dates.publicationStartDate | date: dateFormat
      }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell *matCellDef="let publication">
        <a
          [routerLink]="getWizardUrl(publication.id)"
          *ngIf="!publication.dates.publicationStartDate"
        >
          <button mat-icon-button class="action" (click)="resetCache()">
            <mat-icon
              i18n-matTooltip="@@actionEdit"
              matTooltip="Edit"
              matTooltipPosition="above"
              >edit</mat-icon
            >
          </button>
        </a>
        <a
          [routerLink]="getWizardUrl(publication.id) + '/analysis'"
          *ngIf="publication.dates.publicationStartDate"
        >
          <button mat-icon-button class="action">
            <mat-icon
              i18n-matTooltip="@@wizardAnalysis"
              matTooltip="Analysis"
              matTooltipPosition="above"
              >equalizer</mat-icon
            >
          </button>
        </a>
        <a
          [href]="getPublicationUrl(publication.id)"
          target="_blank"
          *ngIf="publication.dates.publicationStartDate"
        >
          <button mat-icon-button class="action">
            <mat-icon
              i18n-matTooltip="@@actionOpenInNewWindow"
              matTooltip="Open in new window"
              matTooltipPosition="above"
              >launch</mat-icon
            >
          </button>
        </a>
        <button
          mat-icon-button
          class="action"
          (click)="openDeleteDialog(publication)"
        >
          <mat-icon
            i18n-matTooltip="@@actionDelete"
            matTooltip="Delete"
            matTooltipPosition="above"
            >delete</mat-icon
          >
        </button>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
  </mat-table>

  <mat-paginator
    [pageSizeOptions]="[10, 25, 50, 100]"
    showFirstLastButtons
  ></mat-paginator>
</div>
