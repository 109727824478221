<h2 mat-dialog-title i18n="@@homeRequirements">Requirements to your PDF</h2>

<mat-dialog-content>

  <section>
    <mat-icon>lock_open</mat-icon>
    <ul>
      <li>no password</li>
      <li>no encryption</li>
      <li>no protected fonts</li>
    </ul>
  </section>

  <section>
    <mat-icon>transform</mat-icon>
    <ul>
      <li>no double pages</li>
      <li>all pages have identical dimensions</li>
      <li>all fonts completely embedded</li>
    </ul>
  </section>

  <section>
    <mat-icon>palette</mat-icon>
    <ul>
      <li>web-optimized</li>
      <li>RGB color palette</li>
      <li>reduced transparencies</li>
    </ul>
  </section>

</mat-dialog-content>

<mat-dialog-actions>
  <button mat-raised-button
          mat-dialog-close
          i18n="@@actionClose">Close</button>
</mat-dialog-actions>
