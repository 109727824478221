import Bugsnag from '@bugsnag/js';
import { BugsnagErrorHandler } from '@bugsnag/plugin-angular';
import { application } from '@page2flip/core';

import { environment } from '../environments/environment';

const bugsnagClient = Bugsnag.start({
  apiKey: '765e58b48d229b39f1e04c35292a551f',
  appVersion: application.version + ' (#' + application.revision + ')',
  releaseStage: environment.production ? 'production' : 'development',
  enabledReleaseStages: [ 'production' ]
});

export function bugsnagFactory() {
  return new BugsnagErrorHandler(bugsnagClient);
}
