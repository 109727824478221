import { Component } from '@angular/core';

import { application, date, dependencies } from '@page2flip/core';
import { environment } from '../../../environments/environment';

/**
 * Component that represents the info page.
 */
@Component({
  selector: 'p2f-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.css']
})
export class InfoComponent {

  /** Application versions. */
  readonly application: any = application;

  /** Dependency versions. */
  readonly dependencies: any = dependencies;

  /** Build date. */
  readonly date: any = date;

  /** Environment configuration. */
  readonly environment: any = environment;

  /**
   * Constructor of the component.
   */
  constructor() {}

}
