import { Component } from '@angular/core';

import { User } from '@page2flip/core/common';
import { languages } from '@page2flip/i18n';
import { AuthenticationService } from '../../core/services/authentication.service';

/**
 * Component that represents the settings page.
 */
@Component({
  selector: 'p2f-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent {

  /** Current user. */
  currentUser: User = this.auth.currentUser;

  /**
   * Constructor of the component.
   *
   * @param auth  Service to authenticate the user.
   */
  constructor(private auth: AuthenticationService) {}

  /**
   * Gets a languages' native name.
   *
   * @param code  ISO 639-1 language code.
   */
  getLanguageNativeName(code: string) {
    return languages[code].nativeName;
  }

  updateProfile() {
    this.auth.updateProfile(this.currentUser).subscribe();
  }

  updatePassword(currentPassword: string, newPassword: string) {
    this.auth.updatePassword(currentPassword, newPassword).subscribe();
  }

}
