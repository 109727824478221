import {
    FormControl,
    FormGroupDirective,
    NgForm
} from '@angular/forms';

export class ErrorStateMatcher implements ErrorStateMatcher {
    isErrorState(
      control: FormControl | null,
      form: FormGroupDirective | NgForm | null
    ): boolean {
      const isSubmitted = form && form.submitted;
      return !!(
        control &&
        control.invalid &&
        (control.dirty || control.touched || isSubmitted || control.untouched)
      );
    }
}