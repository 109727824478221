import { Injectable } from '@angular/core';

/**
 * Service to manipulate hotspots.
 */
@Injectable()
export class HotspotService {

  /**
   * Scales a hotspot path.
   *
   * Further information about SVG paths can be found here:
   * https://developer.mozilla.org/en-US/docs/Web/SVG/Tutorial/Paths.
   *
   * @param   path  Hotspot path.
   * @param   scale Page scale.
   * @param   zoom  Zoom level.
   * @returns Scaled hotspot path.
   */
  scalePath(path: string, scale: number, zoom: number): string {
    // replace spaces before path commands with commas to make splitting the instructions easier
    path = path.replace(/ ([L|H|V|A|Z])/g, ',$1');

    const instructions: string[] = path.split(',');
    const scaledInstructions: string[] = [];

    instructions.forEach(instruction => {
      const parts: any[] = instruction.split(' ');
      const scaledParts: any[] = [];

      switch (parts[0]) {
        case 'M': // move to
        case 'L': // line to
          scaledParts.push(parts[0]);
          scaledParts.push(parseFloat(parts[1]) * scale * zoom); // x
          scaledParts.push(parseFloat(parts[2]) * scale * zoom); // y
          break;

        case 'H': // horizontal line to
        case 'V': // vertical line to
          scaledParts.push(parts[0]);
          scaledParts.push(parseFloat(parts[1]) * scale * zoom); // x or y
          break;

        case 'A': // elliptical arc
          scaledParts.push(parts[0]);
          scaledParts.push(parseFloat(parts[1]) * scale * zoom); // rx
          scaledParts.push(parseFloat(parts[2]) * scale * zoom); // ry
          scaledParts.push(parseFloat(parts[3]));                // x axis rotation
          scaledParts.push(parseFloat(parts[4]));                // large arc flag
          scaledParts.push(parseFloat(parts[5]));                // sweep flag
          scaledParts.push(parseFloat(parts[6]) * scale * zoom); // x
          scaledParts.push(parseFloat(parts[7]) * scale * zoom); // y
          break;

        case 'Z': // close path
          scaledParts.push(parts[0]);
          break;
      }

      scaledInstructions.push(scaledParts.join(' '));
    });
    return scaledInstructions.join(' ');
  }

}
