import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { switchMap } from 'rxjs/operators';

import { WINDOW } from '@page2flip/core'
import { Instance } from '@page2flip/core/common';
import { environment } from '../../../environments/environment';
import { AuthenticationService } from '../../core/services/authentication.service';
import { SpaceOneInstanceService } from '../../core/services/space-one-instance.service';

/**
 * Component that represents the login page.
 */
@Component({
  selector: 'p2f-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  /** Available space.one instances. */
  spaceOneInstances: Instance[];

  /** Current space.one instance. */
  spaceOneInstance: Instance;

  /** Username. */
  username: string;

  /** Password. */
  password: string;

  /**
   * Constructor of the component.
   *
   * @param window    DI Token for the Window object.
   * @param auth      Service to authenticate the user.
   * @param instance  Service to set the space.one instance.
   * @param router    Provides the navigation and url manipulation capabilities.
   */
  constructor(@Inject(WINDOW) private window: any,
              private auth: AuthenticationService,
              private instance: SpaceOneInstanceService,
              private router: Router) {
    this.spaceOneInstances = instance.spaceOneInstances;
    this.spaceOneInstance = instance.spaceOneInstance;
  }

  /**
   * Navigates to home page if user is already logged in.
   */
  ngOnInit() {
    this.auth.profile().subscribe(user => {
      if (user && user.apiAuthKey) {
        this.router.navigate(['/home']);
      }
    });
  }

  /**
   * Sets the space.one instance.
   */
  setSpaceOneInstance() {
    this.instance.setSpaceOneInstance(this.spaceOneInstance);
  }

  /**
   * Log the user in.
   */
  login() {
    this.auth.login(this.username, this.password)
      .pipe(switchMap(() => this.auth.scope()))
      .subscribe(scopes => {
        // set the default scope
        const scope: string = scopes[0].urlKey;
        this.spaceOneInstance.scope = scope;
        environment.spaceOneScope = scope;
        this.setSpaceOneInstance();

        const redirectUrl: string = this.auth.redirectUrl ? this.auth.redirectUrl : '/home';
        this.router.navigate([redirectUrl]);
      });
  }

}
