/**
 * Viewer page markers.
 */

export class PageMarker {

  id: string;
  /** Number of the page. */
  page: number;

  /** Label of the page marker. */
  tabText: string;

  /** Colors of the page marker. */
  tabColor?: string; // background color
  tabTextColor?: string;// Text color

  constructor(data){
    this.page = data.page;
    this.tabText = data.tabtext;
    this.tabColor = data.tabcolor;
    this.tabTextColor = data.tabtextcolor;
  }
}
