import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';

/**
 * Intercepts HTTP requests and adds authentication credentials to it.
 */
@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {

  /**
   * Intercept an outgoing `HttpRequest` and optionally transform it or the response.
   *
   * @param req   HTTP request.
   * @param next  HTTP handler.
   */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // add credentials only to API requests
    if (req.url.includes(environment.spaceOneApiUrl)) {
      return next.handle(req.clone({withCredentials: true}));
    }

    return next.handle(req);
  }

}
