<div *ngFor="let publication of publications" class="publication">
    <img class="cover mat-elevation-z8" [src]="getCoverUrl(publication.id)" />
    <div class="overlay">
        <div class="meta">
            <span class="title">{{ publication.meta.title }}</span>
            <span class="date">{{
          publication.dates.creationDate | date: dateFormat
        }}</span>
        </div>
        <div class="actions">
            <a [routerLink]="getWizardUrl(publication.id)" *ngIf="!publication.dates.publicationStartDate">
                <button mat-icon-button class="action primary">
                    <mat-icon i18n-matTooltip="@@actionEdit" matTooltip="Edit">edit</mat-icon>
                </button>
            </a>
            <a [routerLink]="getWizardUrl(publication.id) + '/analysis'" *ngIf="publication.dates.publicationStartDate">
                <button mat-icon-button class="action primary">
                    <mat-icon i18n-matTooltip="@@wizardAnalysis" matTooltip="Analysis">equalizer</mat-icon>
                </button>
            </a>
            <a [href]="getPublicationUrl(publication.id)" target="_blank"
                *ngIf="publication.dates.publicationStartDate">
                <button mat-icon-button class="action">
                    <mat-icon i18n-matTooltip="@@actionOpenInNewWindow" matTooltip="Open in new window">launch
                    </mat-icon>
                </button>
            </a>
            <button mat-icon-button class="action" (click)="openDeleteDialog(publication)">
                <mat-icon i18n-matTooltip="@@actionDelete" matTooltip="Delete">delete</mat-icon>
            </button>
        </div>
    </div>
</div>

