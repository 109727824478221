<div class="container">

  <h1 i18n="@@feedback">Feedback</h1>

  <div *ngIf="!submitted && !error" class="content mat-elevation-z16">

    <div class="rating">
      <mat-icon (click)="setRating(1)" [class.selected]="rating === 1" class="red">sentiment_very_dissatisfied</mat-icon>
      <mat-icon (click)="setRating(2)" [class.selected]="rating === 2" class="orange">sentiment_dissatisfied</mat-icon>
      <mat-icon (click)="setRating(3)" [class.selected]="rating === 3" class="yellow">sentiment_neutral</mat-icon>
      <mat-icon (click)="setRating(4)" [class.selected]="rating === 4" class="green">sentiment_satisfied</mat-icon>
      <mat-icon (click)="setRating(5)" [class.selected]="rating === 5" class="darkgreen">sentiment_very_satisfied</mat-icon>
    </div>

    <mat-form-field>
      <mat-select [(ngModel)]="category"
                  i18n-placeholder="@@feedbackSelectCategory"
                  placeholder="Select a category">
        <mat-option [value]="'general'" i18n="@@feedbackCategoryGeneral">General</mat-option>
        <mat-option [value]="'ui'" i18n="@@feedbackCategoryUi">User interface</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <textarea matInput
                p2fStopPropagation
                class="feedback"
                [(ngModel)]="feedback"
                i18n-placeholder="@@feedbackAdditionalInfo"
                placeholder="Tell us a little bit more (optional)"></textarea>
    </mat-form-field>

    <mat-form-field>
      <input matInput
             p2fStopPropagation
             type="email"
             [(ngModel)]="email"
             i18n-placeholder="@@feedbackEmailAddress"
             placeholder="Your email address (optional)">
    </mat-form-field>

    <div class="actions">
      <button mat-raised-button
              [disabled]="!rating || !category"
              [class.hidden]="submitted || error"
              (click)="submit()"
              i18n="@@actionSend">Send</button>
    </div>

  </div>

  <div *ngIf="submitted" class="content mat-elevation-z16">
    <div class="message" i18n="@@feedbackConfirmation">Thank you for helping us making page2flip even better!</div>
  </div>

  <div *ngIf="error" class="content mat-elevation-z16">
    <div class="message" i18n="@@feedbackError">Your feedback could not be submitted. Please try again later.</div>
  </div>

</div>
