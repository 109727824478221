<h2 mat-dialog-title>
  Cookie Einwilligung
</h2>
<mat-dialog-content [innerHtml]="data?.analyticsConfiguration?.cookieConsentDescription"></mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button
          [mat-dialog-close]="false">
    Ablehnen
  </button>
  <button mat-raised-button
          color="primary"
          [mat-dialog-close]="true"
          cdkFocusInitial>
    Zustimmen
  </button>
</mat-dialog-actions>
