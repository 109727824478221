<div class="container">

  <div class="logo-wrapper">
    <img class="logo" src="../../../assets/images/logo-page2flip.svg">
  </div>

  <div class="content mat-elevation-z16">

    <div class="form">

      <mat-form-field>
        <input matInput
               [(ngModel)]="username"
               (keydown.Enter)="login()"
               placeholder="Username"
               i18n-placeholder="@@loginUsername">
      </mat-form-field>

      <mat-form-field>
        <input matInput
               [(ngModel)]="password"
               (keydown.Enter)="login()"
               type="password"
               placeholder="Password"
               i18n-placeholder="@@loginPassword">
      </mat-form-field>

      <mat-form-field>
        <mat-select [(ngModel)]="spaceOneInstance"
                    (selectionChange)="setSpaceOneInstance()"
                    placeholder="space.one instance"
                    i18n-placeholder="@@loginInstance">
          <mat-option *ngFor="let instance of spaceOneInstances"
                      [value]="instance">{{instance.host}}</mat-option>
        </mat-select>
      </mat-form-field>

      <!--<mat-checkbox i18n="@@loginStayLoggedIn">Stay logged in</mat-checkbox>-->

      <button mat-raised-button
              (click)="login()"
              i18n="@@actionLogIn">Log in</button>

    </div>

  </div>

</div>
