import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Inject } from '@angular/core';

import { application, WINDOW } from '@page2flip/core';
import { Feedback } from '@page2flip/core/common';
import { environment } from '../../../environments/environment';

/**
 * Component that represents the feedback page.
 */
@Component({
  selector: 'p2f-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.css']
})
export class FeedbackComponent {

  /** Feedback category. */
  category: string;

  /** User email address (optional). */
  email: string;

  /** User feedback message (optional). */
  feedback: string;

  /** User rating. */
  rating: number;

  /** Whether the feedback has been submitted successfully. */
  submitted: boolean;

  /** Whether there was an error while submitting the feedback. */
  error: boolean;

  /**
   * Constructor of the component.
   *
   * @param window  DI Token for the Window object.
   * @param http    Service to perform HTTP requests.
   */
  constructor(@Inject(WINDOW) private window: any,
              private http: HttpClient) {}

  /**
   * Set the user rating.
   *
   * @param rating User rating.
   */
  setRating(rating: number) {
    this.rating = rating;
  }

  /**
   * Submits the feedback.
   */
  submit() {
    const feedback: Feedback = {
      rating: this.rating,
      category: this.category,
      feedback: this.feedback,
      email: this.email,
      version: `${application.version} #${application.revision}`,
      url: this.window.location.href,
      userAgent: this.window.navigator.userAgent
    };

    const headers: HttpHeaders = new HttpHeaders().set('X-AUTH-KEY', environment.feedbackApiKey);

    this.http.post(environment.feedbackApiUrl, feedback, {headers}).subscribe(
      () => this.submitted = true,
      () => this.error = true
    );
  }

}
