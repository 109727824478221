import { NgModule } from '@angular/core';

import { AuthenticationGuard } from './guards/authentication.guard';
import { AuthenticationInterceptor } from './interceptors/authentication.interceptor';
import { AuthenticationService } from './services/authentication.service';
import { SpaceOneInstanceService } from './services/space-one-instance.service';

@NgModule({
  providers: [
    AuthenticationGuard,
    AuthenticationInterceptor,
    AuthenticationService,
    SpaceOneInstanceService
  ]
})
export class CoreModule {}
