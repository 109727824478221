<div class="container" [perfectScrollbar]>

  <h1 i18n="@@settings">Settings</h1>

  <div class="content mat-elevation-z16">

    <div>
      <mat-label i18n="@@accountTitle">Title</mat-label>
      <mat-radio-group [(ngModel)]="currentUser.gender">
        <mat-radio-button value="male" i18n="@@accountTitleMr">Mr.</mat-radio-button>
        <mat-radio-button value="female" i18n="@@accountTitleMs">Ms.</mat-radio-button>
      </mat-radio-group>
    </div>

    <mat-form-field>
      <input matInput
             [(ngModel)]="currentUser.firstname"
             i18n-placeholder="@@accountFirstName"
             placeholder="First name">
    </mat-form-field>

    <mat-form-field>
      <input matInput
             [(ngModel)]="currentUser.lastname"
             i18n-placeholder="@@accountLastName"
             placeholder="Last name">
    </mat-form-field>

    <mat-form-field>
      <input matInput
             type="email"
             [disabled]="true"
             [value]="currentUser.email"
             i18n-placeholder="@@accountEmail"
             placeholder="Email address">
    </mat-form-field>

    <!-- TODO: show user name -->
    <mat-form-field>
      <input matInput
             [disabled]="true"
             [value]="'user.name'"
             i18n-placeholder="@@accountUsername"
             placeholder="User name">
    </mat-form-field>

    <mat-form-field>
      <mat-select [(ngModel)]="currentUser.userlanguage"
                  [disabled]="true"
                  i18n-placeholder="@@accountLanguage"
                  placeholder="Language">
        <mat-option [value]="'de'">{{getLanguageNativeName('de')}}</mat-option>
        <mat-option [value]="'en'">{{getLanguageNativeName('en')}}</mat-option>
      </mat-select>
    </mat-form-field>

    <div class="actions">
      <button mat-raised-button
              (click)="updateProfile()"
              i18n="@@actionSave">Save</button>
    </div>

  </div>

  <div class="content mat-elevation-z16">

    <mat-form-field>
      <input matInput
             #currentPassword
             type="password"
             i18n-placeholder="@@accountPassword"
             placeholder="Password">
    </mat-form-field>

    <mat-form-field>
      <input matInput
             #newPassword
             type="password"
             i18n-placeholder="@@accountPasswordRetype"
             placeholder="Retype password">
    </mat-form-field>

    <div class="actions">
      <button mat-raised-button
              (click)="updatePassword(currentPassword.value, newPassword.value)"
              i18n="@@actionUpdate">Update</button>
    </div>

  </div>

  <div class="content mat-elevation-z16">

    <mat-form-field>
      <input matInput
             [disabled]="true"
             [value]="currentUser.apiAuthKey"
             i18n-placeholder="@@accountApiAuthKey"
             placeholder="API authorization key">
    </mat-form-field>

    <div class="setting">
      <mat-label i18n="@@accountAuthorities">Authorities</mat-label>
      <span class="value" *ngFor="let authority of currentUser.authorities">{{authority}}</span>
    </div>

    <div class="setting">
      <mat-label i18n="@@accountScopes">Scopes</mat-label>
      <span class="value" *ngFor="let scope of currentUser.accessibleScopes">{{scope.name}}</span>
    </div>

  </div>

</div>
