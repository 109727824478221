<div class="instance">{{ spaceOneInstance | json }}</div>

<div #container class="container" [perfectScrollbar]>
  <section class="intro">
    <img class="logo" src="../../../assets/images/logo-page2flip.svg"/>

    <div *ngIf="!hideIntro">
      <p i18n="@@homeWelcome">Welcome to the all new page2flip!</p>
      <p i18n="@@homeIntro">Drag a PDF file into the window or click the button below to create a new publication.</p>
      <button
        mat-button
        class="upload mat-elevation-z4"
        (click)="file.click()"
        i18n="@@homeLetsRumble"
      >
        Let's rumble!
      </button>
      <input
        type="file"
        accept="application/pdf"
        class="file"
        (input)="onFileInput($event)"
        #file
      />
      <div
        class="requirements"
        (click)="openPDFRequirementsDialog()"
        i18n="@@homeRequirements"
      >
        Requirements to your PDF
      </div>
    </div>

    <div
      *ngIf="!hideIntro"
      class="hide-intro"
      (click)="hideIntro = true"
      i18n="@@homeHideIntro"
    >
      <mat-icon>explore_off</mat-icon>
    </div>

    <div
      *ngIf="hideIntro"
      class="show-intro"
      (click)="hideIntro = false"
      i18n="@@homeShowIntro"
    >
      <mat-icon>explore</mat-icon>
    </div>
  </section>

  <section *ngIf="publications && publications.length">
    <h2 class="header-line">
      <span i18n="@@publicationsLatest">Your last publications</span>
    </h2>

    <div #list class="publications">
      <div
        *ngFor="let publication of unfinishedPublications"
        class="publication unfinished"
      >
        <img class="cover mat-elevation-z8" />
        <div class="progress">{{ publication.progress }}%</div>
        <div class="overlay">
          <div class="meta">
            <span class="title">{{ 'Unfinished publication' }}</span>
            <span class="date">{{ publication.date | date: dateFormat }}</span>
          </div>
          <div class="actions">
            <button
              mat-icon-button
              class="action"
              (click)="openDeleteDialog(publication)">
              <mat-icon i18n-matTooltip="@@actionDelete" matTooltip="Delete"
                >delete</mat-icon>
            </button>
          </div>
        </div>
      </div>

      <p2f-home-publication-list [publications]="publications"></p2f-home-publication-list>
    </div>

    <div *ngIf="showAll" class="publications-gradient"></div>

    <div *ngIf="showAll" class="publications-all">
      <button
        mat-icon-button
        [routerLink]="'/publications'"
        i18n-matTooltip="@@actionShowAll"
        matTooltip="Show all"
        matTooltipPosition="before"
      >
        <mat-icon>list</mat-icon>
      </button>
    </div>
  </section>
</div>

<p2f-uploader (updatePublications)="update($event)"></p2f-uploader>
