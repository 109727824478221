<h2 mat-dialog-title i18n="@@publicationDelete">Delete publication</h2>

<mat-dialog-content>
  <div class="details" *ngIf="publication.meta">
    <img class="cover mat-elevation-z8" [src]="getCoverUrl(publication.id)" />

    <div class="meta">
      <h2 class="header-line">
        <span>{{ publication.meta.title }}</span>
      </h2>
      <h3 *ngIf="publication.meta.description">
        {{ publication.meta.description }}
      </h3>

      <table class="mat-body-2">
        <tr>
          <td i18n="@@publicationLanguage">Language</td>
          <td>{{ getLanguageNativeName(publication.meta.language) }}</td>
        </tr>
        <tr>
          <td i18n="@@publicationKeywords">Keywords</td>
          <td>{{ publication.meta.keywords }}</td>
        </tr>
        <tr>
          <td i18n="@@publicationAuthor">Author</td>
          <td>{{ publication.meta.author }}</td>
        </tr>
        <tr>
          <td i18n="@@publicationDateCreated">Created</td>
          <td>{{ publication.dates.creationDate | date: dateFormat }}</td>
        </tr>
        <tr>
          <td i18n="@@publicationDateModified">Modified</td>
          <td>{{ publication.dates.modificationDate | date: dateFormat }}</td>
        </tr>
        <tr>
          <td i18n="@@publicationDatePublished">Published</td>
          <td>
            {{ publication.dates.publicationStartDate | date: dateFormat }}
          </td>
        </tr>
      </table>
    </div>
  </div>

  <span i18n="@@publicationDeleteMessage"
    >Are you sure you want to delete this publication? Be aware that this cannot
    be undone.</span
  >
</mat-dialog-content>

<mat-dialog-actions>
  <button
    mat-raised-button
    mat-dialog-close
    (click)="delete()"
    i18n="@@actionDelete"
  >
    Delete
  </button>
  <button mat-raised-button mat-dialog-close i18n="@@actionClose">Close</button>
</mat-dialog-actions>
